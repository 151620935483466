import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {Container } from '@gravity-ui/uikit';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ForEdit from './Components/ForEdit';
import ForPreview from './Components/ForPreview';

function App() {

  const ID = "0789d6d72fb218eac4129663bf052df3"
  
  return (
    <Container maxWidth="l" spaceRow={5} style={{marginTop: 20}}>
      <Header />
        <Router>
          <Routes>
            <Route path='/:id' element={<ForPreview />} />
            <Route path='/' element={<ForEdit />} index/>
          </Routes>
        </Router>
      <Footer />
    </Container>
  );
}

export default App;
