import React from "react";
import { TextArea } from "@gravity-ui/uikit";

function TextAreaInput({ onSetData }) {
    return(
        <TextArea size='l' minRows={20} placeholder="Код вставлять сюда" onUpdate={onSetData}/>
    );
}

export default TextAreaInput;


