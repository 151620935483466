export async function sendToServer(token, texttype, textvalue) {
    console.log("token - ", token);
    console.log("texttype - ", texttype);
    console.log("textvalue - ", textvalue);
    
    try {
        const payload = {
            token: token,
            texttype: texttype,
            textvalue: textvalue,
        };

        console.log("Sending data:", payload); // Логирование отправляемых данных

        const response = await fetch('https://d5dke9eiesjmg3dl4k2u.apigw.yandexcloud.net/v1/data/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        console.log("Response:", response); // Логирование основной информации ответа

        const data = await response.json();
        console.log("Response data:", data); // Логирование тела ответа

        if (data.status === 'success') {
            return data.data;
        } else {
            throw new Error(data.message);
        }
    } catch (error) {
        console.error('Error sending data:', error); // Логирование ошибки
        throw error;
    }
}

export async function getFromServer(dataId) {
    try {
        const response = await fetch(`https://d5dke9eiesjmg3dl4k2u.apigw.yandexcloud.net/v1/data/${dataId}`, {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error('Ошибка получения данных');
        }

        return await response.json();
    } catch (error) {
        console.error('Ошибка:', error);
        throw error;
    }
}