import React from "react";
import { Col, Container, Row, Skeleton} from "@gravity-ui/uikit";
import TextBlock from "./Preview/TextBlock";
import TextType from "./Preview/TextType";
import TextLink from "./Preview/TextLink";
import { useParams } from 'react-router-dom';
import { getFromServer } from "../api";
import WantButton from "./Preview/WantButton";
import {useToaster} from '@gravity-ui/uikit';

function ForPreview() {
    const {add: ShowMessage} = useToaster();
    //тут стейты все пишем
    const [CodeText, setCodeText] = React.useState("...");
    const [CodeType, setCodeType] = React.useState("...");
    const [IsLoaded, setLoaded] = React.useState(false);
    const { id } = useParams();



        const onClick = () => {
        console.log("Copy click!");
            
        const url = document.URL;
    
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(url)
                .then(() => {
                    ShowMessage({
                        title: 'Адрес скопирован в буфер',
                        type: 'success',
                        isClosable: false,
                        autoHiding: 3000,
                    });
                })
                .catch(err => {
                    ShowMessage({
                        title: 'Что-то пошло не так',
                        content: String(err),
                        type: 'error',
                    });
                    console.log('Something went wrong', err);
                });
        } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            const textAreaElement = document.createElement('textarea');
            textAreaElement.value = url;
            document.body.appendChild(textAreaElement);
            textAreaElement.select();
    
            try {
                document.execCommand('copy');
                ShowMessage({
                    title: 'Адрес скопирован в буфер',
                    type: 'success',
                    isClosable: false,
                    autoHiding: 3000,
                });
            } catch (err) {
                ShowMessage({
                    title: 'Что-то пошло не так',
                    content: String(err),
                    type: 'error',
                });
                console.error('Something went wrong', err);
            } finally {
                document.body.removeChild(textAreaElement);
            }
        } else {
            ShowMessage({
                title: 'Копирование не поддерживается',
                type: 'error',
            });
            console.error('Copy is not supported');
        }
    }
    

    React.useEffect(() => {
        getFromServer(id)
          .then((response) => {
            console.log("ответ:", response);
            console.log("тип данных:", response.data.texttype);
            console.log("значение данных:", response.data.textvalue);
            setCodeType(response.data.texttype);
            setCodeText(response.data.textvalue);
            setLoaded(true)
          })
          .catch((error) => {
            ShowMessage({
                title: 'Что то пошло не так',
                content: String(error),
                type: 'error',
              });
              console.error(error);
          });
      }, [id]);
    
    if (IsLoaded) {
        return (
            <Container style={{marginBottom: 10}}>
            <Row space={5} style={{marginBottom: 10, alignItems: "center"}}>
                <Col s={12} m={6}>
                    <TextLink ID={id} onClick={onClick}/>
                </Col>
                <Col s={12} m={3} >
                    <TextType CodeType={CodeType}/>
                </Col>
                <Col s={12} m={3}>
                    <WantButton />
                </Col>
            </Row>
            <Row space={5}>
                <Col s={12}>
                    <TextBlock CodeText={CodeText} CodeType={CodeType}/>
                </Col>
            </Row>
            </Container>
        );
    } else {
        return (
            <Container style={{marginBottom: 10}}>
            <Row space={5} style={{marginBottom: 10, alignItems: "center"}}>
                <Col s={12} m={6}>
                    <Skeleton style={{height: 35}}/>
                </Col>
                <Col s={12} m={3} >
                    <Skeleton style={{height: 35}}/>    
                </Col>
                <Col s={12} m={3}>
                    <WantButton />
                </Col>
            </Row>
            <Row space={5}>
                <Col s={12}>
                    <Skeleton style={{height: 200}}/>
                </Col>
            </Row>
            </Container>
        );
    }

}

export default ForPreview;