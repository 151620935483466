import React from "react";
import { Col, Row, Text } from "@gravity-ui/uikit";

function Header() {
    return(
        <Row>
            <Row>
                <Col s={12}>
                    <Text variant="display-4">
                        Сократ
                    </Text>
                </Col>
                <Col s={12}>
                    <Text variant="caption-2">
                        преврати большой текст в ссылку
                    </Text>
                </Col>
            </Row>
        </Row>
    );
}

export default Header;