import React from "react";
import { Container, Link, Text, Button, Icon } from "@gravity-ui/uikit";
import {Files} from '@gravity-ui/icons';


function TextLink( {ID, onClick} ) {
    const href = "/"+ID
    return(
        <Container>
            <Text variant="subheader-1">Идентификатор: </Text>
            <Link href={href}>{ID}</Link>
            <Button size="l" view="flat" onClick={onClick}>
                <Icon data={Files} size={18}/>
            </Button>
        </Container>
    );
}

export default TextLink;