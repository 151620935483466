import React from "react";
import { Icon, Button } from "@gravity-ui/uikit";
import {FilePlus} from '@gravity-ui/icons';

function WantButton() {
    return(
        <Button view="action" size="l" width="max" href="/">
            Создать
            <Icon data={FilePlus} size={18} />
        </Button>
    );
}

export default WantButton;