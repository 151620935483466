import React from "react";
import { Col, Row, Text, Link } from "@gravity-ui/uikit";

function Footer() {
    return(
        <Row>
            <Col s={12}>
                <Text variant="body-1">
                &#169; {new Date().getFullYear()} <Link view="normal" href="https://idfactory.ru">Ideas Factory</Link>
                </Text>
            </Col>
        </Row>
    );
}

export default Footer;