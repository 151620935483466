import React from "react";
import { Col, Row } from "@gravity-ui/uikit";
import TextAreaInput from "./Edit/TextAreaInput";
import TypeSelector from "./Edit/TypeSelector";
import SubmitButton from "./Edit/SubmitButton";
import { sendToServer } from "../api";
import {useToaster} from '@gravity-ui/uikit';

function ForEdit() {
    const {add: ShowError} = useToaster();

    const token = 'testtoken'
    
    //тут стейты все пишем
    const [CodeText, setCodeText] = React.useState(null);
    const [CodeType, setCodeType] = React.useState(null);
    const [IsLoading, setLoading] = React.useState(false);

    //функция для изменения стейтов
    const handleDataFromTextArea = (value) => {
        setCodeText(value);
        console.log("Изменение текста:", value);
    }
    const handleDataFromTypeSelector = (value) => {
        setCodeType(value[0]);
        console.log("Изменение типа:", value[0]);
    }

    //функция для отправки кода на сервер
    const sendData = () => {
        if (CodeText == null || CodeType == null) {
            console.log("fail");
            ShowError({
                title: 'Что то пошло не так',
                content: 'Не хватает данных: нет текста или не указан тип текста',
                type: 'error',
              });
            return;
        }
        setLoading(true)
        console.log("success")
        console.log("Передаем токен:", token);
        console.log("Передаем тип:", CodeType);
        console.log("Передаем текст:", CodeText);
        sendToServer(token, CodeType, CodeText).then(response => {
            console.log('success');
            console.log(response.id);
            window.location.href = '/'+response.id;
        }).catch(error => {
            console.log('fail');
            console.error(error);
            setLoading(false)
            ShowError({
                title: 'Что то пошло не так',
                content: String(error),
                type: 'error',
              });
        });
      }
    

    //отрисовываем картинку
    return (
        <Row space={5}>
            <Col s={12} m={8} l={10}>
                <TextAreaInput onSetData={handleDataFromTextArea}/>
            </Col>
            <Col s={12} m={4} l={2}>
                <Row>
                    <Col s={12}><TypeSelector onSetData={handleDataFromTypeSelector}/></Col>
                </Row>
                <Row>
                    <Col s={12} style={{marginTop: 10}}><SubmitButton onClick={sendData} isLoading={IsLoading} /></Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ForEdit;