import React from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Card, Text } from "@gravity-ui/uikit";

function TextBlock( {CodeText, CodeType} ) {
    return(
        <Card>
            <Text whiteSpace="break-spaces" variant="code-1" >
                <SyntaxHighlighter language={CodeType} style={vs2015} showLineNumbers='true'>
                    {CodeText}
                </SyntaxHighlighter>   
            </Text>
        </Card>
    );
}

export default TextBlock;