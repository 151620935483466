import React from "react";
import { Select } from "@gravity-ui/uikit";

function TypeSelector({ onSetData }) {
    return(
        <Select
            size='l'
            placeholder='Тип текста'
            width='max'
            onUpdate={ onSetData }
            options={[
                {
                    value: 'cpp',
                    content: 'cpp',
                },
                {
                    value: 'go',
                    content: 'go',
                },
                {
                    value: 'javascript',
                    content: 'javascript',
                },
                {
                    value: 'json',
                    content: 'json',
                },
                {
                    value: 'plaintext',
                    content: 'plaintext',
                },
                {
                    value: 'python',
                    content: 'python',
                },
                {
                    value: 'sql',
                    content: 'sql',
                },
            ]}
        />
    );
}

export default TypeSelector;