import React from "react";
import { Container, Text } from "@gravity-ui/uikit";

function TextType({CodeType}) {
    return(
        <Container>
            <Text variant="subheader-1">Тип: </Text>
            <Text variant="body-1">{CodeType}</Text>
        </Container>
        
    );
}

export default TextType;