import React from "react";
import { Icon, Button } from "@gravity-ui/uikit";
import {FileArrowRightOut} from '@gravity-ui/icons';

function SubmitButton({onClick, isLoading}) {
    return(
        <Button view="action" size="l" width="max" onClick={onClick} loading={isLoading}>
            Сохранить
            <Icon data={FileArrowRightOut} size={18} />
        </Button>
    );
}

export default SubmitButton;